'use client';

import {
  Progress as ProgressComponent,
  ProgressProps,
} from '@tajdid/tajdid-ui';

export default function Button({ ...rest }: ProgressProps) {
  return <ProgressComponent  {...rest} />;
}
