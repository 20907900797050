'use client';

import { assetsUrl } from '@/config';
import { Button } from '@/ui';
import { cn } from '@tajdid/tajdid-ui';
import Image from 'next/image';
import Link from 'next/link';

export type PlaceholderProps = {
  imgSrc: string;
  title: string;
  description: string;
  className?: string;
  button?: {
    label: string;
    href: string;
  };
  fullHeight?: boolean;
};

export type PlaceholderPageProps = Partial<
  Pick<
    PlaceholderProps,
    'title' | 'description' | 'className' | 'fullHeight' | 'button'
  >
> & {
  featureName?: string;
  onClick?: () => void;
};

const Placeholder = ({
  imgSrc,
  button,
  title,
  description,
  className,
}: PlaceholderProps) => {
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center h-full gap-6 text-center py-10',
        className,
      )}
    >
      <Image
        className="object-cover rounded-tl-sm rounded-tr-sm"
        alt="placeholder image"
        src={`${assetsUrl}/assets/images/${imgSrc}`}
        width={400}
        height={300}
      />
      <h1 className="text-2xl font-bold text-gray-900 first-letter:capitalize">
        {title}
      </h1>
      <p className="text-base text-gray-600 font-normal  w-[459px]">
        {description}
      </p>
      {button && (
        <Link href={button.href}>
          <Button>{button.label}</Button>
        </Link>
      )}
    </div>
  );
};

export default Placeholder;
