import { cn } from '@/utils';
import { PlaceholderPageProps } from './placeholder';

const LoopPlaceholder = ({
  title = 'Loading...',
  description = 'Please wait while we load the page...',
  className,
  fullHeight,
}: PlaceholderPageProps) => {
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center h-full gap-6 text-center',
        fullHeight && 'min-h-screen',
        className,
      )}
    >
      <div className="loader"></div>
      <h1 className="text-2xl font-bold text-gray-900 first-letter:capitalize">
        {title}
      </h1>
      <p className="text-base text-gray-600 font-normal  w-[459px]">
        {description}
      </p>
    </div>
  );
};

export default LoopPlaceholder;
