import Placeholder, { PlaceholderPageProps } from './placeholder';

const ErrorPlaceholder = ({
  title,
  description,
  onClick,
}: PlaceholderPageProps) => {
  return (
    <Placeholder
      button={{ label: 'Try again', href: '/' }}
      imgSrc="error-placeholder.png"
      title={title || 'Temporary unavailability'}
      description={
        description ||
        "Apologies, we're currently facing technical difficulties preventing data display. We're actively working on a solution. Thank you for your patience."
      }
    />
  );
};

export default ErrorPlaceholder;
